import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Shortcut } from "@sumit-platforms/types";
import { useTranslation } from "react-i18next";
import { useDetectDevice } from "../../../hooks";
import classNames from "classnames";
import { useKeyPressRecorder } from "../../hooks/useKeypressRecorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faLock,
  faUnlock,
} from "@fortawesome/pro-light-svg-icons";
import { Tooltip } from "../Tooltip/Tooltip";
import _ from "lodash";
import { macosShortcuts, windowsShortcuts } from "../../../constants";
import { prettifyKey, replaceModifiers } from "../../utils/string-formatter";

export const KeyboardShortcutItem = ({
  shortcut,
  updateLocalUpdates,
  userShortcuts,
}: {
  shortcut: Shortcut;
  updateLocalUpdates: (shortcut: Shortcut) => void;
  userShortcuts: Shortcut[];
}) => {
  const { t } = useTranslation();
  const { isMacOS } = useDetectDevice();
  const [listenToKeyStroke, setListenToKeyStroke] = useState(false);
  const [conflict, setConflict] = useState<"user" | "os" | null>(null);
  const { allKeysReleased, keysPressed } = useKeyPressRecorder({
    listen: listenToKeyStroke,
  });
  const [updatedShortcut, setUpdatedShortcut] = useState(_.cloneDeep(shortcut));

  const otherUserShortcuts = useMemo(
    () =>
      userShortcuts.filter((s) => {
        const sameAction = s.action === updatedShortcut.action;
        if (s.editorModes?.length) {
          const commonEditorType =
            _.intersection(s.editorModes, updatedShortcut.editorModes).length >
            0;
          return commonEditorType && !sameAction;
        }
        return !sameAction;
      }),
    [userShortcuts]
  );

  const keyCodeToString = useCallback(
    (key: string) => key.replace(/Key/gi, ""),
    []
  );

  useEffect(() => {
    const osShortcuts = isMacOS ? macosShortcuts : windowsShortcuts;

    const userConflict = otherUserShortcuts.some((otherShortcut) => {
      const conflict =
        _.intersection(
          replaceModifiers(otherShortcut.keys, { isMacOS }),
          replaceModifiers(updatedShortcut.keys, { isMacOS })
        ).length > 0;

      return conflict;
    });

    const osConflict =
      _.intersection(
        replaceModifiers(updatedShortcut.keys, { toLowerCase: true, isMacOS }),
        osShortcuts
      ).length > 0;

    if (osConflict) {
      setConflict("os");
    }
    if (userConflict) {
      setConflict("user");
    }
    if (!userConflict && !osConflict) {
      setConflict(null);
    }
  }, [updatedShortcut, otherUserShortcuts, allKeysReleased]);

  const toggleListenToUserKeystroke = useCallback(() => {
    if (updatedShortcut.disabled || updatedShortcut.editable === false) return;

    setListenToKeyStroke(!listenToKeyStroke);
  }, [listenToKeyStroke, updatedShortcut]);

  const toggleDisable = useCallback(() => {
    setUpdatedShortcut({
      ...updatedShortcut,
      disabled: !updatedShortcut.disabled,
    });
  }, [updatedShortcut]);

  useEffect(() => {
    if (allKeysReleased) {
      setUpdatedShortcut({
        ...updatedShortcut,
        action: shortcut.action,
        keys: [keysPressed],
      });
      setListenToKeyStroke(false);
    }
  }, [allKeysReleased]);

  useEffect(() => {
    updateLocalUpdates(updatedShortcut);
  }, [updatedShortcut]);

  return (
    <Box className={"shortcutActionItem"}>
      <p
        className={classNames("actionTitle", {
          disabled: updatedShortcut.disabled,
          strikeOut: updatedShortcut.disabled,
        })}
      >
        {t(shortcut?.action?.toLowerCase())}
      </p>
      <Box
        className={classNames("keysCombinationWrapper", {
          warningBorder: conflict === "os",
          errorBorder: conflict === "user",
          disabled:
            updatedShortcut.editable === false || updatedShortcut.disabled,
          strikeOut: updatedShortcut.disabled,
        })}
        onClick={toggleListenToUserKeystroke}
      >
        <Tooltip
          title={t(
            conflict === "user"
              ? "user_shortcut_conflict"
              : "os_shortcut_conflict"
          )}
        >
          <span
            className={classNames("conflict", {
              user: conflict === "user",
              os: conflict === "os",
              hide: !conflict,
            })}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </span>
        </Tooltip>
        <div
          className={classNames("keysCombinationInput", {
            grayPlaceholder: listenToKeyStroke,
          })}
        >
          {listenToKeyStroke
            ? t("set_keys")
            : prettifyKey(updatedShortcut.keys, { isMacOS })}
        </div>
      </Box>
      <Tooltip
        title={
          updatedShortcut.disabled
            ? t("enable_shortcut")
            : t("disable_shortcut")
        }
      >
        <span
          className={classNames("lock", {
            hide: updatedShortcut.editable === false,
          })}
          onClick={toggleDisable}
        >
          <FontAwesomeIcon
            icon={updatedShortcut.disabled ? faUnlock : faLock}
          />
        </span>
      </Tooltip>
    </Box>
  );
};
