import { useCallback } from "react";
import { MenuItem, ShortcutAction } from "@sumit-platforms/types";
import {
  useDetectDevice,
  useKeyboardShortcuts,
} from "@sumit-platforms/ui-bazar/hooks";
import { prettifyKey } from "@sumit-platforms/ui-bazar/utils";
import { Checkbox } from "@sumit-platforms/ui-bazar";
import * as Menubar from "@radix-ui/react-menubar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquare,
  faCheckSquare,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";

const DefaultIconSlot = ({ item }: { item: MenuItem }) =>
  item.leftIcon ? (
    <FontAwesomeIcon icon={item.leftIcon} size={"1x"} />
  ) : (
    <span style={{ width: "15px" }} />
  );

export const MenuItemContent = ({ item }: { item: MenuItem }) => {
  const { userShortcuts } = useKeyboardShortcuts();
  const { isMacOS } = useDetectDevice();

  const getUserShortcut = useCallback(
    (shortcutAction: ShortcutAction) => {
      const shortcut = userShortcuts.find((sc) => sc.action === shortcutAction);
      if (!shortcut) return "";
      return prettifyKey(shortcut.keys, { isMacOS });
    },
    [isMacOS, userShortcuts]
  );

  return (
    <div className="MenuItemContent">
      <div className="IconSlot">
        {item.type === "toggle" && item.checked ? (
          <Menubar.CheckboxItem
            // className="MenubarCheckboxItem"
            key={item.id}
            checked={item.checked}
            disabled={item.disabled}
          >
            <Checkbox
              checked={item.checked}
              icon={
                <FontAwesomeIcon
                  size={"1x"}
                  icon={faSquare}
                  color={"#FDB810"}
                />
              }
              checkedIcon={
                <FontAwesomeIcon
                  size={"1x"}
                  icon={faCheckSquare}
                  color={"#FDB810"}
                />
              }
              sx={{
                padding: 0,
                cursor: "inherit",
                "&:hover": { backgroundColor: "transparent" },
                "& .MuiTouchRipple-root": { display: "none" },
              }}
            />
          </Menubar.CheckboxItem>
        ) : (
          <DefaultIconSlot item={item} />
        )}
      </div>

      <span>{item.name}</span>
      <div className="RightSlot">
        {/*TODO align string result from KeyboardShortcutItem component*/}
        {item.shortcutId ? getUserShortcut(item.shortcutId) : null}
        {item.rightIcon ? (
          <FontAwesomeIcon icon={item.rightIcon} size={"1x"} />
        ) : item.subItems ? (
          <FontAwesomeIcon icon={faChevronRight} size={"1x"} />
        ) : null}
      </div>
    </div>
  );
};
