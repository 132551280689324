import React, { forwardRef } from "react";
import Editor, { EditorProps, EditorImperativeRef } from "./editor";
import { RecoilRoot } from "recoil";
import {
  aiJobChatServerUrlState,
  featureFlagsState,
  fpsState,
  karaokeState,
  subtitlesValidationState,
} from "./store/states";
import { MenuProvider } from "./store/context/useMenuBar.context";

const EditorRoot = (editorProps: EditorProps, ref?: React.Ref<any>) => {
  return (
    <RecoilRoot
      initializeState={({ set }) => {
        const { featureFlags, userSettings, startAsReadOnly, jobSettings } =
          editorProps;

        const karaokeInitialValue = featureFlags?.karaoke
          ? startAsReadOnly
            ? userSettings?.karaokeMode
            : startAsReadOnly
          : false;

        const initialFpsValue =
          (editorProps?.job?.media?.length
            ? editorProps?.job?.media[0]?.fps
            : 25) || 25;

        set(featureFlagsState, featureFlags || {});
        set(fpsState, initialFpsValue);
        if (editorProps?.featureFlags?.karaoke) {
          set(karaokeState, !!karaokeInitialValue);
        }
        set(subtitlesValidationState, jobSettings.subtitlesValidation);
        set(aiJobChatServerUrlState, editorProps?.aiJobChatServerUrl || "");
      }}
    >
      <MenuProvider>
        <Editor {...editorProps} ref={ref} />
      </MenuProvider>
    </RecoilRoot>
  );
};
export type { EditorImperativeRef };
export default forwardRef(EditorRoot);
