import React, { HTMLAttributes } from "react";
import CheckboxMui from "@mui/material/Checkbox";
import { SxProps } from "@mui/material";

import "./Checkbox.scss";

export interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
  icon: React.ReactNode;
  checkedIcon: React.ReactNode;
  checked: boolean;
  sx?: SxProps;
}

export const Checkbox = ({
  icon,
  checkedIcon,
  checked,
  onChange,
  sx,
}: CheckboxProps) => {
  return (
    <div className="BazarCheckbox">
      <CheckboxMui
        icon={icon}
        checkedIcon={checkedIcon}
        checked={checked}
        onChange={onChange}
        sx={sx}
      />
    </div>
  );
};
