import { MenuBar } from "@sumit-platforms/types";

import "./MenuBar.scss";
import Box from "@mui/material/Box";
import { MenuBarBadges } from "./MenuBarBadges";
import { useEllipsisObserver } from "@sumit-platforms/ui-bazar/hooks";
import { useRef } from "react";

export const MenubarTitle = ({
  title,
  badges,
}: {
  badges: MenuBar["badges"];
  title: MenuBar["menuTitle"];
}) => {
  return (
    <Box className={"MenubarTitle "}>
      <span className={"ellipsis"}>{title || ""}</span>
      <MenuBarBadges badges={badges} />
    </Box>
  );
};
