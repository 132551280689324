import React, { useCallback, useState } from "react";
import { Stack } from "@mui/material";
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { IconButton, Input } from "../../../index";
import { useTranslation } from "react-i18next";

interface ChatInputProps {
  onSend: (input: string) => void;
  isLoading: boolean;
  placeholder: string;
}

export const ChatInput: React.FC<ChatInputProps> = ({
  onSend,
  isLoading,
  placeholder,
}) => {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState("");

  const onInputChange = useCallback((e: any) => {
    setInputText(e.target.value);
  }, []);

  const handleOnSend = useCallback(() => {
    if (isLoading || !inputText?.trim()?.length) {
      return;
    }
    onSend(inputText);
    setInputText("");
  }, [inputText, onSend]);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Input
        className="askAnythingInput"
        style={{ width: "100%", padding: 1 }}
        placeholder={placeholder}
        value={inputText}
        onChange={onInputChange}
        onEnter={handleOnSend}
        autofocus
        autoDir
        multiline
      />
      <IconButton
        onClick={handleOnSend}
        disabled={!inputText.trim() || isLoading}
        icon={faPaperPlane}
        size="large"
        tooltipText={t("send")}
      />
    </Stack>
  );
};
