import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { Box, Typography } from "@mui/material";
import { failedColor, failedColor2, primaryColor } from "../../../index";

interface ChatErrorIconProps {
  message?: string;
}

export const ChatErrorIcon = ({
  message = "An error occurred",
}: ChatErrorIconProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        p: 1,
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          width: 20,
          height: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faCircleExclamation}
          size={"xs"}
          color={failedColor}
        />
      </Box>
      {message && (
        <Typography
          variant="body1"
          color="error"
          sx={{
            fontWeight: 400,
            fontSize: 14,
          }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
};
