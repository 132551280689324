import React from "react";
import { MenuBar } from "@sumit-platforms/types";
import { Box } from "@mui/material";
import { MenuBarCategory } from "./MenuBarCategory";

import "./MenuBar.scss";

export const MenubarCategories = ({
  categories,
}: {
  categories: MenuBar["categories"];
}) => {
  return (
    <Box className="MenubarCategories" display={"flex"}>
      {categories?.map((category) => (
        <MenuBarCategory key={category.name} category={category} />
      ))}
    </Box>
  );
};
