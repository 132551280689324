import React from "react";
import { MenuCategory } from "@sumit-platforms/types";
import { MenuCategoryItem } from "./MenuCategoryItem";
import * as Menubar from "@radix-ui/react-menubar";

export const MenuBarCategory = ({ category }: { category: MenuCategory }) => {
  return (
    <Menubar.Menu>
      <Menubar.Trigger className="MenubarTrigger">
        {category.name}
      </Menubar.Trigger>
      <Menubar.Portal>
        <Menubar.Content
          className="MenubarContent"
          align="start"
          sideOffset={5}
          alignOffset={-3}
        >
          {category.items
            .filter((item) => !item.hide)
            .map((item) => (
              <React.Fragment key={item.id}>
                {item.topSeparator && (
                  <Menubar.Separator className="MenubarSeparator" />
                )}
                <MenuCategoryItem item={item} />
                {item.bottomSeparator && (
                  <Menubar.Separator className="MenubarSeparator" />
                )}
              </React.Fragment>
            ))}
        </Menubar.Content>
      </Menubar.Portal>
    </Menubar.Menu>
  );
};
