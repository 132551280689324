import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChatBox } from "./ChatBox";
import { ChatInput } from "./ChatInput";
import {
  ConversationDialog,
  ConversationHistory,
  JobAiChatMessage,
} from "@sumit-platforms/types";
import { aiChatService } from "../../services/aiChatService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { LoadingDots } from "../../core/LoadingDots/LoadingDots";
import { v4 as uuidv4 } from "uuid";
import { removeDoubleAsterisks } from "../../../utils";

import "./AskAnything.scss";

interface AskAnythingProps {
  serverUrl: string;
  idJob: number;
}

export const AskAnything = ({ serverUrl, idJob }: AskAnythingProps) => {
  const chatService = useMemo(() => aiChatService(serverUrl), [serverUrl]);

  const { t } = useTranslation();
  const [messages, setMessages] = useState<ConversationHistory>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [messagesLoadingState, setMessagesLoadingState] = useState({});
  const [questionResponseError, setQuestionResponseError] = useState<{
    message?: string;
  } | null>(null);

  const init = useCallback(async () => {
    const messages = await chatService.getConversationHistory({ idJob });
    setMessages(messages);
    return messages ?? [];
  }, []);

  const onQuestionSuccess = useCallback((dialog: ConversationDialog) => {
    setIsLoading(false);

    setMessages((prev) => {
      const updatedMessages = [...prev];
      updatedMessages[updatedMessages.length - 1] = dialog;
      return updatedMessages;
    });
    setMessagesLoadingState((prev) => ({ ...prev, [dialog.idMessage]: false }));
  }, []);

  const onRerunQuestionSuccess = useCallback(
    (updatedDialog?: JobAiChatMessage | null) => {
      setIsLoading(false);
      setMessagesLoadingState({});
      if (!updatedDialog) return;
      setMessages((prev) => {
        const updatedMessages = [...prev];
        const idx = prev.findIndex(
          (msg) => msg.idMessage === updatedDialog.idMessage
        );
        if (idx >= 0 && _.isArray(updatedMessages[idx].ai)) {
          updatedMessages[idx].ai?.push(updatedDialog);
        }
        return updatedMessages;
      });
    },
    []
  );

  const onFeedbackQuestionSuccess = useCallback(
    (updatedDialog: ConversationDialog) => {
      setMessages((prev) => {
        const idx = prev.findIndex(
          (msg) => msg.idMessage === updatedDialog.idMessage
        );
        if (idx >= 0) {
          const updatedMessages = [...prev];
          updatedMessages[idx] = updatedDialog;
          return updatedMessages;
        }
        return prev;
      });
      setIsLoading(false);
    },
    []
  );

  const onQuestionError = useCallback((error: any) => {
    setIsLoading(false);
    setShowSpinner(false);
    setMessagesLoadingState({});
    setQuestionResponseError({
      message: "Error occurred.",
    });
    console.error("Error sending message:", error);
  }, []);

  const createQuestionMutationFn = useCallback(
    async (inputText: string) => {
      setQuestionResponseError(null);
      setIsLoading(true);
      const idMessage = uuidv4();
      setMessagesLoadingState((prev) => ({ ...prev, [idMessage]: true }));
      const newDialog: ConversationDialog = {
        ai: null,
        user: {
          idJob,
          idUser: -1, // idUser is retrieved from the request cookie in the BE. it will update on success
          question: inputText,
          idMessage,
        },
        idMessage,
      };

      setMessages((prev) => [...prev, newDialog]);

      return await chatService.sendPrompt({
        question: inputText,
        idJob,
        idMessage,
      });
    },
    [setMessagesLoadingState, setMessages]
  );

  const createQuestion = useMutation({
    mutationKey: ["question", idJob, messages?.length],
    mutationFn: createQuestionMutationFn,
    onError: onQuestionError,
    onSuccess: onQuestionSuccess,
  });

  const rerunQuestionMutationFn = useCallback(
    async (idMessage: string) => {
      const message = messages.find((m) => m.idMessage === idMessage);
      if (!message || !message?.ai) return null;

      return await chatService.rerunQuestion({ idMessage });
    },
    [messages]
  );

  const rerunQuestion = useMutation({
    mutationKey: ["rerunQuestion", idJob, messages?.length],
    mutationFn: rerunQuestionMutationFn,
    onError: onQuestionError,
    onSuccess: onRerunQuestionSuccess,
  });

  const feedbackMutation = useMutation({
    mutationKey: ["feedbackQuestion", idJob, messages?.length],
    mutationFn: async ({
      idMessage,
      feedback,
      idJobAiChat,
    }: {
      idMessage: string;
      feedback: JobAiChatMessage["feedback"];
      idJobAiChat: number;
    }) => {
      return await chatService.feedback({ idMessage, feedback, idJobAiChat });
    },
    onError: onQuestionError,
    onSuccess: onFeedbackQuestionSuccess,
  });

  const handleSend = useCallback(
    (inputText: string) => {
      if (!inputText?.trim()) return;
      createQuestion.mutate(inputText);
    },
    [createQuestion]
  );

  const handleRerun = useCallback(
    (idMessage: string) => {
      if (!idMessage) return;
      setIsLoading(true);
      setMessagesLoadingState((prev) => ({ ...prev, [idMessage]: true }));
      setQuestionResponseError(null);
      rerunQuestion.mutate(idMessage);
    },
    [rerunQuestion]
  );

  const handleFeedback = useCallback(
    async (
      idMessage: string,
      feedback: JobAiChatMessage["feedback"],
      idJobAiChat: number
    ) => {
      setIsLoading(true);
      return feedbackMutation.mutate({ idMessage, feedback, idJobAiChat });
    },
    [feedbackMutation]
  );

  const { data, isLoading: isInitialLoading } = useQuery({
    // TODO: is the query client should be at app level or editor level?
    queryKey: ["conversationHistory", idJob],
    queryFn: init,
  });

  const handleCopy = useCallback((text: string) => {
    navigator.clipboard.writeText(removeDoubleAsterisks(text));
  }, []);

  return (
    <Box dir="ltr">
      <Box className="AskAnything" p={2}>
        {isInitialLoading ? (
          <Box>
            <LoadingDots />
          </Box>
        ) : (
          <ChatBox
            messages={messages}
            isLoading={isLoading}
            showSpinner={showSpinner}
            messagesLoadingState={messagesLoadingState}
            onFeedback={handleFeedback}
            onCopy={handleCopy}
            onRerun={handleRerun}
            questionResponseError={questionResponseError}
          />
        )}
        <ChatInput
          onSend={handleSend}
          isLoading={isLoading}
          placeholder={t("ask_anything")}
        />
      </Box>
    </Box>
  );
};
