import { JobAiChatMessage } from "@sumit-platforms/types";
import { ChatMessage } from "./ChatMessage";
import { ChatMessageActions } from "./ChatMessageActions";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { LoadingDots } from "../../../index";

export const AiChatMessages = ({
  aiMessages,
  showSpinner,
  loading,
  onCopy,
  onFeedback,
  onRerun,
}: {
  aiMessages: JobAiChatMessage[];
  onFeedback: (
    idMessage: string,
    feedback: JobAiChatMessage["feedback"],
    idJobAiChat: number
  ) => void;
  onCopy: (text: string) => void;
  onRerun: (messageId: string) => void;
  loading: boolean;
  showSpinner: boolean;
}) => {
  const [currentAiMessageIndex, setCurrentAiMessageIndex] = useState(0);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  console.log("currentAiMessageIndex :", currentAiMessageIndex);
  const scrollToMessageBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [messagesEndRef]);

  const onIndexChange = useCallback(
    async (idx: number) => {
      setCurrentAiMessageIndex(idx);
      setTimeout(scrollToMessageBottom, 50);
    },
    [scrollToMessageBottom]
  );

  useLayoutEffect(() => {
    if (!aiMessages.length) return;
    onIndexChange(aiMessages?.length - 1);
  }, [aiMessages?.length]);

  return (
    <>
      <ChatMessage
        message={aiMessages[currentAiMessageIndex]}
        isAiMessage={true}
      />
      <ChatMessageActions
        currentIndex={currentAiMessageIndex || 0}
        totalResponses={aiMessages?.length || 0}
        onIndexChange={onIndexChange}
        message={aiMessages[currentAiMessageIndex]}
        onFeedback={onFeedback}
        onCopy={onCopy}
        onRerun={onRerun}
        isLoading={loading}
      />
      {showSpinner && <LoadingDots />}
      <div ref={messagesEndRef} />
    </>
  );
};
