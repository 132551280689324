import { MenuItem } from "@sumit-platforms/types";
import { MenuItemContent } from "./MenuItemContent";
import * as Menubar from "@radix-ui/react-menubar";

export const MenuCategoryItem = ({ item }: { item: MenuItem }) => {
  if (item.subItems) {
    //TODO: currently not implemented!
    return (
      <Menubar.Sub key={item.name}>
        <Menubar.SubTrigger className="MenubarSubTrigger">
          <MenuItemContent item={item} />
        </Menubar.SubTrigger>
        <Menubar.Portal>
          <Menubar.SubContent className="MenubarSubContent" alignOffset={-5}>
            {item.subItems?.map((subItem) => (
              <MenuCategoryItem item={subItem}></MenuCategoryItem>
            ))}
          </Menubar.SubContent>
        </Menubar.Portal>
      </Menubar.Sub>
    );
  }

  return (
    <Menubar.Item
      className="MenubarItem"
      key={item.name}
      disabled={item.disabled}
      onClick={item.action as any}
    >
      <MenuItemContent item={item} />
    </Menubar.Item>
  );
};
