import React from "react";
import { Stack, Typography } from "@mui/material";
import { IconButton } from "../../../index";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";

interface ChatMessagePaginationProps {
  totalResponses: number;
  currentIndex: number;
  onIndexChange: (index: number) => void;
  disabled?: boolean;
}

export const ChatMessagePagination: React.FC<ChatMessagePaginationProps> = ({
  totalResponses,
  currentIndex,
  onIndexChange,
  disabled,
}) => {
  if (totalResponses <= 1) return null;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <IconButton
        icon={faChevronLeft}
        disabled={currentIndex === 0 || disabled}
        onClick={() => onIndexChange(currentIndex - 1)}
      />
      <Typography variant="caption">
        {currentIndex + 1} / {totalResponses}
      </Typography>
      <IconButton
        icon={faChevronRight}
        disabled={currentIndex === totalResponses - 1 || disabled}
        onClick={() => onIndexChange(currentIndex + 1)}
      />
    </Stack>
  );
};
