import React, { useState, useCallback } from "react";
import { Stack } from "@mui/material";
import _ from "lodash";
import {
  faCopy,
  faCheck,
  faRedo,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/pro-light-svg-icons";
import { IconButton } from "../../../index";
import { JobAiChatMessage } from "@sumit-platforms/types";
import { ChatMessagePagination } from "./ChatMessagePagination";
import { useTranslation } from "react-i18next";

interface MessageActionsProps {
  message: JobAiChatMessage;
  onFeedback: (
    idMessage: string,
    feedback: JobAiChatMessage["feedback"],
    idJobAiChat: number
  ) => void;
  onCopy: (text: string) => void;
  onRerun: (messageId: string) => void;
  currentIndex: number;
  totalResponses: number;
  onIndexChange: (index: number) => void;
  isLoading: boolean;
}

export const ChatMessageActions: React.FC<MessageActionsProps> = ({
  message,
  onFeedback,
  onCopy,
  onRerun,
  currentIndex,
  totalResponses,
  onIndexChange,
  isLoading,
}) => {
  if (!message) return null;
  const [copied, setCopied] = useState(false);

  const { t } = useTranslation();
  const handleOnCopy = useCallback(
    (text: string) => {
      setCopied(true);
      onCopy(text);
    },
    [onCopy]
  );

  const handleOnFeedback = useCallback(
    (message: JobAiChatMessage, feedback: JobAiChatMessage["feedback"]) => {
      const currMessageFeedback = message.feedback;

      if (!_.isNil(currMessageFeedback) && currMessageFeedback === feedback) {
        // if the feedback clicked twice
        feedback = null;
      }

      onFeedback(message.idMessage, feedback, message.idJobAiChat || -1);
    },
    []
  );

  return (
    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
      <IconButton
        onClick={() => handleOnFeedback(message, "like")}
        selected={message.feedback === "like"}
        icon={faThumbsUp}
        tooltipText={t("helpful")}
        disabled={isLoading}
      />
      <IconButton
        onClick={() => handleOnFeedback(message, "dislike")}
        selected={message.feedback === "dislike"}
        icon={faThumbsDown}
        tooltipText={t("not_helpful")}
        disabled={isLoading}
      />
      <IconButton
        onClick={() => handleOnCopy(message.answer)}
        icon={copied ? faCheck : faCopy}
        tooltipText={t("copy")}
        disabled={isLoading}
      />
      <IconButton
        onClick={() => onRerun(message.idMessage)}
        icon={faRedo}
        tooltipText={t("rerun")}
        disabled={isLoading}
      />
      <ChatMessagePagination
        totalResponses={totalResponses}
        currentIndex={currentIndex}
        onIndexChange={onIndexChange}
        disabled={isLoading}
      />
    </Stack>
  );
};
