import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ReactEditor,
  RenderElementProps,
  Slate,
  withReact,
} from "@sumit-platforms/slate-react";
import { faWandMagicSparkles } from "@fortawesome/pro-light-svg-icons";
import { createEditor, Transforms } from "@sumit-platforms/slate";
import { withHistory } from "@sumit-platforms/slate-history";
import Range from "./components/Slate/Range";
import EditorService from "./services/EditorService";
import { EditorMode, Job, JobSettings, MenuItem } from "@sumit-platforms/types";
import { CustomEditor, CustomElement, EditorAction, LeafProps } from "./types";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";
import {
  featureFlagsState,
  isAskAnythingOpenState,
  isBetweenRangesState,
  isDisabledState,
  isFindAndReplaceOpenState,
  isJobScriptOpenState,
  isValidationsOpenState,
  repeatState,
  startIndexState,
} from "./store/states";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { UpdateTcOffsetFn } from "./editor";
import Leaf from "./components/Slate/Leaf";
import { withRangeBreakOrMerge } from "./interceptors/withRangeBreakOrMerge";
import { withPlainTextPaste } from "./interceptors/withPlainTextPaste";
import TranscriptEditor from "./TranscriptEditor";
import SubtitlesEditor from "./SubtitlesEditor";
import _ from "lodash";
import { withJobSettings } from "./interceptors/withJobSettings";
import { withEdgeNav } from "./interceptors/withEdgeNav";
import { withMode } from "./interceptors/withMode";
import { useTranslation } from "react-i18next";
import { useMenuBar } from "./store/context/useMenuBar";

export type SlateForwardedRef = {
  editor: CustomEditor;
  updateLastRangeInput: () => void;
  onCurrentTimeUpdate?: (time: number) => void;
  lastFindAndReplaceTerm?: string;
};

export type EditorForwardedRef = {
  editorRef?: HTMLElement | null;
  lastFindAndReplaceTerm?: string;
  onStartIndexChange?: (index: number) => void;
};

interface SlateEditorProps {
  job: Job;
  updateTcOffset: UpdateTcOffsetFn;
  isFindAndReplaceOpen: boolean;
  toggleIsFindAndReplaceOpen: () => void;
  handleReadOnlyClick: () => void;
  mode: EditorMode;
  jobSettings: JobSettings;
  addActions: (actions: EditorAction[]) => void;
  isTranslationMode: boolean;
}

export interface ContentScrollProps {
  onIndexChange: (index: number) => void;
  onScrollStop: (index: number) => void;
  onScrollStart: (index: number) => void;
  subtitleHeight: number;
  mediaHeight: number;
  visibleRanges: number;
}

const SlateEditor = (
  {
    job,
    updateTcOffset,
    mode,
    jobSettings,
    handleReadOnlyClick,
    addActions,
    isTranslationMode,
  }: SlateEditorProps,
  ref: React.Ref<SlateForwardedRef>
) => {
  const { t } = useTranslation();
  const featureFlags = useRecoilValue(featureFlagsState);
  const isDisabled = useRecoilValue(isDisabledState);
  const setRepeat = useSetRecoilState(repeatState);
  const setStartIndex = useSetRecoilState(startIndexState);
  const setIsBetweenRanges = useSetRecoilState(isBetweenRangesState);

  const [isAskAnythingOpen, setIsAskAnythingOpen] = useRecoilState(
    isAskAnythingOpenState
  );

  const [isFindAndReplaceOpen, setIsFindAndReplaceOpen] = useRecoilState(
    isFindAndReplaceOpenState
  );

  const [isJobScriptOpen, setIsJobScriptOpen] =
    useRecoilState(isJobScriptOpenState);
  const [isValidationOpen, setIsValidationOpen] = useRecoilState(
    isValidationsOpenState
  );

  const [editorController] = useState(() =>
    withMode(
      withEdgeNav(
        withJobSettings(
          withPlainTextPaste(
            withRangeBreakOrMerge(
              withHistory(withReact(createEditor() as CustomEditor))
            )
          )
        )
      )
    )
  );

  const editorRef = useRef<EditorForwardedRef>(null);
  const inputLanguage = useRef(
    job.inputLanguage[0] || job.jobSplit?.sourceJob?.inputLanguage[0]
  );

  const updateLastRangeInput = useCallback(
    (rangeIndex?: number) => {
      const entry = _.isNumber(rangeIndex)
        ? {
            element: editorController.children[rangeIndex] as CustomElement,
            path: rangeIndex,
          }
        : null;
      EditorService.updateElementRange({
        editor: editorController,
        entry,
      });
    },
    [editorController]
  );
  const onSpeakersRangeBlur = useCallback(
    (rangeIndex?: number) => {
      updateLastRangeInput(rangeIndex);
    },
    [updateLastRangeInput]
  );

  const onSubtitlesRangeBlur = useCallback(
    (rangeIndex?: number) => {
      if (!_.isNumber(rangeIndex)) {
        console.error("rangeIndex does not provided");
        return;
      }

      updateLastRangeInput(rangeIndex);
    },
    [updateLastRangeInput]
  );

  const handleFindAndReplaceKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      if (isDisabled || !featureFlags.findAndReplace) return;
      if (isFindAndReplaceOpen) {
        setIsFindAndReplaceOpen(false);
      } else {
        e.preventDefault();

        if (mode === "subtitles") {
          ReactEditor.deselect(editorController);
        }

        setIsFindAndReplaceOpen(true);

        setIsJobScriptOpen(false);
        setIsValidationOpen(false);
        setIsAskAnythingOpen(false);
      }
    },
    [
      isDisabled,
      featureFlags.findAndReplace,
      isFindAndReplaceOpen,
      setIsFindAndReplaceOpen,
      mode,
      setIsJobScriptOpen,
      setIsValidationOpen,
      setIsAskAnythingOpen,
      editorController,
    ]
  );

  const handleBlur = useCallback(
    (rangeIndex?: number) => {
      if (mode === "subtitles") onSubtitlesRangeBlur(rangeIndex);
      if (mode === "transcript") onSpeakersRangeBlur(rangeIndex);
    },
    [mode, onSpeakersRangeBlur, onSubtitlesRangeBlur]
  );
  const jumpToWord = useCallback(() => {
    EditorService.jumpToSlateWord(editorController);
  }, [editorController]);

  const handleJumpToWordKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      jumpToWord();
    },
    [jumpToWord]
  );

  const handleCloseActionsSectionKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDisabled) return;

      if (isFindAndReplaceOpen) {
        setIsFindAndReplaceOpen(false);
      }
    },
    [isDisabled, isFindAndReplaceOpen, setIsFindAndReplaceOpen]
  );

  const onCurrentTimeUpdate = useCallback(
    (time: number) => {
      if (editorController.handleTimeChange) {
        editorController.handleTimeChange(time);
      }
    },
    [editorController]
  );

  useImperativeHandle(
    ref,
    () => ({
      editor: editorController,
      updateLastRangeInput,
      onCurrentTimeUpdate,
      lastFindAndReplaceTerm: editorRef.current?.lastFindAndReplaceTerm,
    }),
    [editorController, updateLastRangeInput, onCurrentTimeUpdate]
  );

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      if (editorController.history.undos.length) {
        // Clear first mount operations from history
        editorController.history.redos = [];
        editorController.history.undos = [];
      }
    });
  }, []);

  useEffect(() => {
    if (jobSettings && editorController) {
      editorController.jobSettings = jobSettings;
    }
  }, [jobSettings, editorController]);

  const updateContentScrollToRecoilState = useCallback(
    (props: RenderElementProps) => {
      setStartIndex(props.startIndex || null);
      setIsBetweenRanges(!!props.isBetweenRanges);
    },
    [setStartIndex, setIsBetweenRanges]
  );

  const renderElement = useCallback(
    (props: RenderElementProps) => {
      if (isTranslationMode) {
        updateContentScrollToRecoilState(props);
      }
      return (
        <Range
          updateTcOffset={updateTcOffset}
          hideTimecode={["protocol", "brief"].includes(job?.type.typeName)}
          handleBlur={handleBlur}
          startIndex={props.startIndex}
          attributes={props.attributes}
          children={props.children}
          element={props.element}
          renderIndex={props.renderIndex}
          isMediaRange={props.isMiddleRange}
          isPlaceholder={props.isPlaceholder}
          isElementSelected={props.selected}
          editorElement={editorRef.current?.editorRef}
        />
      );
    },
    [
      updateTcOffset,
      job?.type.typeName,
      handleBlur,
      isTranslationMode,
      editorRef,
    ]
  );

  const renderLeaf = useCallback(
    (props: any) => <Leaf {...(props as LeafProps)} />,
    []
  );

  const undo = useCallback(() => {
    editorController.undo();
    EditorService.createWaveformRanges(editorController, isDisabled);
  }, [editorController, isDisabled]);

  const redo = useCallback(() => {
    editorController.redo();
    EditorService.createWaveformRanges(editorController, isDisabled);
  }, [editorController, isDisabled]);

  const isEditorFocused = useCallback(() => {
    const activeElement = document.activeElement;
    if (editorRef.current?.editorRef && activeElement) {
      const isActiveElementDescendantOfEditor =
        editorRef.current.editorRef.contains(activeElement);

      return !isActiveElementDescendantOfEditor;
    }
    return false;
  }, []);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter" && event.shiftKey && !isEditorFocused()) {
        // In case the active element is part of the editor, we wont prevent enter
        event.preventDefault();
        Transforms.insertText(editorController, "\n", {});
      }
      if (event.key === "Enter" && mode === "subtitles" && !isEditorFocused()) {
        // In case the active element is part of the editor, we wont prevent enter
        event.preventDefault(); // Modify under "BREAK_RANGE" shortcut action
      }
      if (event.key === "״") {
        event.preventDefault();
        Transforms.insertText(editorController, '"');
      }
      if (event.key === "`") {
        event.preventDefault();
        Transforms.insertText(editorController, "'");
      }
      if (event.code === "KeyZ" && event.metaKey) {
        //Prevent native undo/redo from user
        event.preventDefault();
        event.stopPropagation();
        ReactEditor.deselect(editorController);
        if (event.shiftKey) redo();
        else undo();
        return;
      }
      if (event.code === "ArrowDown" && mode === "subtitles") {
        EditorService.onArrowUpAndDown(editorController, "next");
      }
      if (event.code === "ArrowUp" && mode === "subtitles") {
        EditorService.onArrowUpAndDown(editorController, "prev");
      }

      if (event.code === "Backspace" && mode === "subtitles") {
        EditorService.onBackspace(editorController, event);
      }
    },
    [editorController, redo, undo, mode, isAskAnythingOpen]
  );

  useKeyboardShortcuts({
    handlers: {
      PREVENT_CUT: EditorService.preventCut,
      JUMP_TO_WORD: handleJumpToWordKeystroke,
    },
    ref: editorRef.current?.editorRef,
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  useKeyboardShortcuts({
    handlers: {
      CLOSE_MODAL: handleCloseActionsSectionKeystroke,
      FIND_AND_REPLACE: handleFindAndReplaceKeystroke,
    },
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  return (
    <Slate
      editor={editorController}
      initialValue={EditorService.formatJobDataToEditorValue(job, mode)}
    >
      {mode === "transcript" && (
        <TranscriptEditor
          editorController={editorController}
          job={job}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          ref={editorRef}
          handleBlur={() => handleBlur()}
          handleReadOnlyClick={handleReadOnlyClick}
        />
      )}
      {mode === "subtitles" && (
        <SubtitlesEditor
          idJob={job.idJob}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          ref={editorRef}
          handleBlur={(rangeIndex) => handleBlur(rangeIndex)}
          isTranslationMode={isTranslationMode}
          inputLanguage={inputLanguage.current}
        />
      )}
    </Slate>
  );
};

export default forwardRef(SlateEditor);
