import axios from "axios";
import {
  ConversationHistory,
  ConversationDialog,
  JobAiChatMessage,
} from "@sumit-platforms/types";

export const aiChatService = (serverUrl: string) => {
  const aiChatAxios = axios.create({
    baseURL: serverUrl,
    withCredentials: true,
    headers: {
      contentType: "application/json",
    },
  });

  const getConversationHistory = async ({ idJob }: { idJob: number }) => {
    try {
      const result = await aiChatAxios.get<ConversationHistory>(
        `/conversation-history`,
        {
          params: { idJob },
        }
      );
      return result.data;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  };

  const sendPrompt = async ({
    question,
    idMessage,
    idJob,
  }: {
    question: string;
    idMessage: string;
    idJob: number;
  }) => {
    try {
      const result = await aiChatAxios.post<ConversationDialog>(`/question`, {
        question,
        idMessage,
        idJob,
      });
      return result.data;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  };

  const rerunQuestion = async ({ idMessage }: { idMessage: string }) => {
    try {
      const result = await aiChatAxios.post<JobAiChatMessage>(
        `/rerun-question`,
        {
          idMessage,
        }
      );
      return result.data;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  };

  const feedback = async ({
    idMessage,
    idJobAiChat,
    feedback,
  }: {
    idMessage: string;
    idJobAiChat: number;
    feedback: JobAiChatMessage["feedback"];
  }) => {
    try {
      const result = await aiChatAxios.post<ConversationDialog>(`/feedback`, {
        feedback,
        idMessage,
        idJobAiChat,
      });
      return result.data;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  };

  return {
    getConversationHistory,
    sendPrompt,
    rerunQuestion,
    feedback,
  };
};
