import { createContext, SetStateAction, useMemo, useState } from "react";
import { MenuBar } from "@sumit-platforms/types";

interface MenuContextType {
  setMenuBar: React.Dispatch<SetStateAction<MenuBar>>;
  menuBar: MenuBar;
}

export const MenuContext = createContext<MenuContextType | null>(null);

export const MenuProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [menuBar, setMenuBar] = useState<MenuBar>({});

  const value = useMemo(
    () => ({
      menuBar,
      setMenuBar,
    }),
    [menuBar, setMenuBar]
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};
