import { MenuCategory, MenuItem } from "@sumit-platforms/types";

export const mergeItems = (
  existing: MenuItem[] = [],
  incoming: MenuItem[] = []
): MenuItem[] => {
  const itemsMap = new Map(existing.map((item) => [item.id, item]));

  // Update or add new items
  incoming.forEach((item) => {
    itemsMap.set(item.id, item);
  });

  // Convert back to array and sort
  return Array.from(itemsMap.values()).sort(
    (a, b) => (a.sortIndex ?? Infinity) - (b.sortIndex ?? Infinity)
  );
};

export const mergeCategories = (
  existing: MenuCategory[] = [],
  incoming: MenuCategory[] = []
): MenuCategory[] => {
  const categoryMap = new Map<string, MenuCategory>();

  // First, add all existing categories to the map
  existing.forEach((category) => {
    categoryMap.set(category.name, {
      ...category,
      items: [...category.items],
    });
  });

  // Then merge incoming categories
  incoming.forEach((incomingCategory) => {
    const existingCategory = categoryMap.get(incomingCategory.name);

    if (!existingCategory) {
      // If category doesn't exist, add it with sorted items
      categoryMap.set(incomingCategory.name, {
        ...incomingCategory,
        items: [...incomingCategory.items].sort(
          (a, b) => (a.sortIndex ?? Infinity) - (b.sortIndex ?? Infinity)
        ),
      });
    } else {
      // If category exists, use the mergeItems function to handle item merging
      categoryMap.set(incomingCategory.name, {
        ...existingCategory,
        ...incomingCategory,
        items: mergeItems(existingCategory.items, incomingCategory.items),
      });
    }
  });

  // Convert map back to array
  return Array.from(categoryMap.values());
};
