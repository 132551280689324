import React, { useCallback, useLayoutEffect, useRef } from "react";
import { Box } from "@mui/material";
import { LoadingDots } from "../../../index";
import { ConversationHistory, JobAiChatMessage } from "@sumit-platforms/types";
import { ChatErrorIcon } from "./ChatErrorIcon";
import { ChatDialog } from "./ChatDialog";

interface ChatBoxProps {
  messages: ConversationHistory;
  isLoading: boolean;
  messagesLoadingState: Record<string, boolean>;
  showSpinner: boolean;
  questionResponseError?: { message?: string } | null;
  onFeedback: (
    idMessage: string,
    feedback: JobAiChatMessage["feedback"],
    idJobAiChat: number
  ) => void;
  onCopy: (text: string) => void;
  onRerun: (messageId: string) => void;
}

export const ChatBox: React.FC<ChatBoxProps> = ({
  messages,
  isLoading,
  messagesLoadingState,
  showSpinner,
  onFeedback,
  onCopy,
  onRerun,
  questionResponseError,
}) => {
  const chatEndRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(false);

  const scrollToBottom = useCallback(() => {
    chatEndRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  useLayoutEffect(() => {
    if (!isLoading && !isFirstRender.current) {
      setTimeout(() => {
        scrollToBottom();
        isFirstRender.current = true;
      }, 100);
    }
  }, [isLoading]); // Scroll after messages initialized

  return (
    <Box
      className={"ChatBox"}
      sx={{
        flex: 1,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: "0 8px",
      }}
    >
      {messages.map((dialog) => (
        <ChatDialog
          key={dialog.idMessage}
          dialog={dialog}
          onFeedback={onFeedback}
          onCopy={onCopy}
          onRerun={onRerun}
          loading={isLoading}
          messagesLoadingState={messagesLoadingState}
        />
      ))}
      {showSpinner && <LoadingDots />}
      {questionResponseError && (
        <ChatErrorIcon message={questionResponseError?.message} />
      )}
      <div className={"chatEndRef"} ref={chatEndRef} />
    </Box>
  );
};
