import React from "react";
import * as RadixMenubar from "@radix-ui/react-menubar";
import { MenubarCategories } from "./MenuBarCategories";
import { MenubarTitle } from "./MenuBarTitle";
import { MenuBarFeaturedButtons } from "./MenuBarFeaturedButtons";
import { useMenuBar } from "../../store/context/useMenuBar";

import "./MenuBar.scss";

export const Menubar = () => {
  const menuBar = useMenuBar();

  return (
    <RadixMenubar.Root className="MenubarRoot">
      <MenubarCategories categories={menuBar.categories} />
      <MenubarTitle title={menuBar.menuTitle} badges={menuBar.badges} />
      <MenuBarFeaturedButtons buttons={menuBar.featuredButtons} />
    </RadixMenubar.Root>
  );
};
